import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../../contexts/authContext";
import useNorthstar from "../../../../hooks/northstarHook";
import useURLQueryParameter from "../../../../hooks/queryParamHook";
import { Link, navigate } from "gatsby";
import NorthstarPageLayout from "../../../../components/NorthstarPageLayout";
import { SCOPES } from "../../../../constants";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import LoadingStatus from "../../../../components/LoadingStatus";
import ReportViewer from "../../../../components/CompanyResearchPrivates/ReportViewer";

const Report = () => {
  const { accessToken, clearIdentity } = useAuthContext();
  const [id] = useURLQueryParameter("id");

  const [data, setData] = useState(null);

  const {
    data: reportData,
    isLoading,
    error,
  } = useNorthstar(`/api/transcript-privates/${id}`, accessToken, {});

  useEffect(() => {
    if (reportData) {
      setData(reportData);
    }
  }, [JSON.stringify(reportData || "")]);

  useEffect(() => {
    let ignore = true;
    if (error?.status === 401 && !ignore && clearIdentity) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [error?.status]);

  useEffect(() => {
    if (!id) {
      navigate("/company_research");
    }
  }, [id]);

  const renderReport = () => {
    if (error) return <LoadingStatus loadingType="report_details_error" />;
    if (isLoading)
      return <LoadingStatus loadingType="report_details_loading" />;
    if (!data) return <LoadingStatus loadingType="report_details_error" />;

    return <ReportViewer report={data} />;
  };

  return (
    <NorthstarPageLayout title="Report" requiredScope={SCOPES.COMPANY_RESEARCH}>
      <div className="flex w-full items-center flex-col font-roboto">
        <div className="max-w-[1200px]">
          <nav
            className="flex mt-3 font-bebas-neue tracking-wide"
            aria-label="Breadcrumb"
          >
            <div className="flex items-center space-x-4">
              <Link
                to="/company_research"
                className="text-blue hover:text-blue-light"
              >
                ALL REPORTS
              </Link>
              <ChevronRightIcon
                className="h-3 w-3 flex-shrink-0"
                aria-hidden="true"
              />
              <div className="text-orange">{data?.company}</div>
            </div>
          </nav>
          {renderReport()}
        </div>
      </div>
    </NorthstarPageLayout>
  );
};

export default Report;
