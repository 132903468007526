import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import ReportSection from "../ReportViewer/ReportSection";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import Disclaimer from "../Disclaimer";
import { formatReportingPeriod } from "./utils";

import { DateTime } from "luxon";

const ReportViewer = ({ report }) => {
  const title = () => {
    return `${report.company_name} AI Insights 
      ${
        report.report_start_date && report.report_date
          ? `(${DateTime.fromISO(report.report_start_date).toFormat(
              "MMM yyyy",
            )} - ${DateTime.fromISO(report.report_date).toFormat("MMM yyyy")})`
          : ""
      }`;
  };
  return (
    <AnimatePresence>
      <motion.div
        className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 
      border-4 bg-white border-gray-200/90 border-t-blue shadow-lg"
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          stiffness: 50,
          duration: 0.4,
        }}
      >
        <div className="my-3 pl-2 pr-8 sm:pl-4 sm:pr-12 pt-3 pb-2">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
            {title()}
          </h1>
          <div className="mt-3 md:mt-4 xl:mt-5 text-sm text-gray-800">
            {formatReportingPeriod(
              report.report_start_date,
              report.report_date,
            )}
          </div>
          <div className="text-blue mt-2 md:mt-3 xl:mt-4">
            <a
              href={`mailto:dse@bamelevate.com`}
              className="flex gap-x-1 font-bebas-neue tracking-wide text-lg"
            >
              <div>
                <span className="sr-only">Email</span>
                <EnvelopeIcon className="w-6 h-6 mt-px" />
              </div>
              Contact
            </a>
          </div>
        </div>
        <div className="mt-1 xl:text-lg">
          {report.body.map((section) => {
            let type;
            if (typeof section.content === "string") type = "text";
            if (Array.isArray(section.content)) type = "list";
            return (
              <ReportSection
                type={type}
                section={section}
                defaultOpen={section.highlight}
              />
            );
          })}
        </div>
        <Disclaimer />
      </motion.div>
    </AnimatePresence>
  );
};

export default ReportViewer;
